import { CourtSlot } from "./GetAvailabilities";

async function GetPricingForSlot(baseUrl: string, location: string, date: string, slot: CourtSlot): Promise<number> {
    const pricingEndpoint = '/pricing';
    const urlToFetch = baseUrl + pricingEndpoint;
    const body = {
        location: location,
        startDate: date + 'T' + slot.startTime + 'Z',
    }
    const response = await fetch(urlToFetch, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    });
    return parseFloat(await response.text());
}

export default GetPricingForSlot;