import { DateTime } from "luxon";
import { ApiResponseLocation, CourtSlot } from "./GetAvailabilities";

export const getSlotDiff = (slot: CourtSlot, searchTime: string) => {
    const aDateTime = DateTime.fromISO(searchTime);
    const bDateTime = DateTime.fromISO(slot.startTime);
    return bDateTime.diff(aDateTime, 'minutes').minutes;
};

export const findClosestTimeSlots = (slots: CourtSlot[], searchTime: string) => {
    let closestSlotIndex = null;
    let smallestDiff = Infinity;

    slots.forEach((slot, index) => {
        const diff = getSlotDiff(slot, searchTime);
        const absDiff = Math.abs(diff);
            
        if (absDiff < smallestDiff || (absDiff === smallestDiff && diff < 0)) {
            smallestDiff = absDiff;
            closestSlotIndex = index;
        }
    });

    if (closestSlotIndex === null) {
        return {
            previous: null,
            closest: null,
            next: null
        };
    }

    const previousSlot = closestSlotIndex > 0 ? slots[closestSlotIndex - 1] : null;
    const closestSlot = slots[closestSlotIndex];
    const nextSlot = closestSlotIndex < slots.length - 1 ? slots[closestSlotIndex + 1] : null;

    const result = {
        previous: previousSlot,
        closest: closestSlot,
        next: nextSlot
    };

    return result;
};

export const slotIsAvailable = (slot: CourtSlot) => slot.availability == null || slot.availability > 0

export const filterResponseForTime = (apiResponse: ApiResponseLocation[], searchTime: string, minutesRange: number) => apiResponse.map(apiResult => {
    return {
        ...apiResult,
        dates: apiResult.dates.map(d => (
            {
                ...d,
                slots: d.slots.filter(s => Math.abs(getSlotDiff(s, searchTime)) <= minutesRange && slotIsAvailable(s))
            }
        ))
    }
}) 
   
