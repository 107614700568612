import { DateTime } from 'luxon';
import { ApiReponseError, CourtSlot } from './GetAvailabilities';

export const slotDuration = (slot: CourtSlot) => {
    const start = DateTime.fromISO(slot.startTime);
    const end = DateTime.fromISO(slot.endTime);
    return end.diff(start, 'minutes').minutes;
};

export const slotComparator = (a: CourtSlot, b: CourtSlot) => {
    const aDateTime = DateTime.fromISO(a.startTime);
    const bDateTime = DateTime.fromISO(b.startTime);
    return aDateTime.diff(bDateTime, 'minutes').minutes; //ToDo - this isn't the right way to compare, sorting probably isn't working
};

export const formatTime = (timeString: string) => {
    const dateTime = DateTime.fromISO(timeString);
    return dateTime.toLocaleString(DateTime.TIME_24_SIMPLE);
};

// "Sunday, 22 October"
export const formatDate = (date: DateTime) => {
    return date.toLocaleString({ month: 'long', day: 'numeric', weekday: 'long' });
};

// "22/10/2023"
export const formatDateShort = (dateString: string) => {
    const dateTime = DateTime.fromISO(dateString);
    return dateTime.toLocaleString();
};

const gbpFormat = Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' });

export const formatPrice = (priceString: number) => {
    return gbpFormat.format(priceString);
};

export const formatApiError = (error: ApiReponseError) => {
    return `Search returned an error for ${formatDateShort(error.date)}. Check and try again.`
}

export const nextHour = () => { 
    const hourNow = DateTime.now().hour
    const minutesNow = DateTime.now().minute
    const hourToShow = minutesNow < 31 ? hourNow : DateTime.now().plus(60 * 60000).hour
    const hourString = hourToShow < 10 ? "0" + hourToShow : `${hourToShow}`
    const minuteString = minutesNow < 31 ? "30" : "00"
    return `${hourString}:${minuteString}`
}

export const timeOptions = Array.from({length: 33}, (_,i) => `${String(Math.floor(i/2) + 7).padStart(2, '0')}:${(i%2)*30}`.padEnd(5, '0'));
export const dateOptions = Array.from({length: 9}, (_, i) => DateTime.now().plus({days: i}));