import { Coords } from "./Geography"

export type Provider = 'places' | 'better' | 'activeLambeth' | 'everyoneActive'

enum TransportMode {
    Tube,
    NationalRail,
    Overground
}

export type Transport = {
    mode: TransportMode,
    station: string
}

export type CourtLocation = {
    provider: Provider,
    displayName: string,
    alternativeSites: string[],
    transport: Transport
    coordinates: Coords
}

export const Locations: Record<string, CourtLocation> = {
    "Balham": {
        provider: 'places',
        displayName: 'Balham Leisure Centre',
        alternativeSites: ['Tooting', 'brixton-recreation-centre', 'crystal-palace-leisure-centre', 'canons-leisure-centre', 'QueenMother'],
        transport: { mode: TransportMode.Tube, station: 'Balham' },
        coordinates: { lat: 51.4408, lng: -0.1519 }
    },
    "oasis-sports-centre": {
        provider: 'better',
        displayName: 'Oasis Leisure Centre',
        alternativeSites: ['finsbury-leisure-centre', 'ironmonger-row-baths', 'britannia-leisure-centre', 'Porchester', 'QueenMother'],
        transport:{mode: TransportMode.Tube, station: 'Tottenham Court Road'},
        coordinates: { lat: 51.5159, lng: -0.1257 }
    },
    "Tooting": {
        provider: 'places',
        displayName: 'Tooting Leisure Centre',
        alternativeSites: ['Balham', 'canons-leisure-centre', 'crystal-palace-leisure-centre', 'brixton-recreation-centre', 'Cheam'],
        transport: {mode: TransportMode.Tube, station: 'Tooting Broadway'},
        coordinates: { lat: 51.4295, lng: -0.1730 }
    },
    "Tolworth": {
        provider: 'places',
        displayName: 'Tolworth Recreation Centre',
        alternativeSites: ['Cheam', 'canons-leisure-centre', 'Tooting', 'Balham'],
        transport: {mode: TransportMode.NationalRail, station: 'Tolworth'},
        coordinates: { lat: 51.3747, lng: -0.2969 }
    },
    "Epping": {
        provider: 'places',
        displayName: 'Epping Sports Centre',
        alternativeSites: ['walthamstow-leisure-centre', 'clissold-leisure-centre', 'britannia-leisure-centre', 'finsbury-leisure-centre', 'ironmonger-row-baths'],
        transport: {mode: TransportMode.Tube, station: 'Epping'},
        coordinates: { lat: 51.6933, lng: 0.1144 }
    },
    "britannia-leisure-centre": {
        provider: 'better',
        displayName: 'Britannia Leisure Centre',
        alternativeSites: ['finsbury-leisure-centre', 'ironmonger-row-baths', 'clissold-leisure-centre', 'oasis-sports-centre', 'walthamstow-leisure-centre'],
        transport: {mode: TransportMode.Overground, station: 'Hoxton'},
        coordinates: { lat: 51.5351, lng: -0.0791 }
    },
    "swiss-cottage-leisure-centre": {
        provider: 'better',
        displayName: 'Swiss Cottage Leisure Centre',
        alternativeSites: ['oasis-sports-centre', 'Porchester', 'finsbury-leisure-centre', 'ironmonger-row-baths', 'kensington-leisure-centre'],
        transport: {mode: TransportMode.Tube, station: 'Swiss Cottage'},
        coordinates: { lat: 51.5433, lng: -0.1724 }
    },
    "kensington-leisure-centre": {
        provider: 'better',
        displayName: 'Kensington Leisure Centre',
        alternativeSites: ['WestwayPortobello', 'Porchester', 'hammersmith-fitness-squash-centre', 'oasis-sports-centre', 'swiss-cottage-leisure-centre'],
        transport: {mode: TransportMode.Tube, station: 'Latimer Road'},
        coordinates: { lat: 51.5159, lng: -0.2104 }
    },
    "clissold-leisure-centre": {
        provider: 'better',
        displayName: 'Clissold Leisure Centre',
        alternativeSites: ['britannia-leisure-centre', 'walthamstow-leisure-centre', 'finsbury-leisure-centre', 'ironmonger-row-baths'],
        transport: {mode: TransportMode.Overground, station: 'Stoke Newington'},
        coordinates: { lat: 51.5635, lng: -0.0774 }
    },
    "walthamstow-leisure-centre": {
        provider: 'better',
        displayName: 'Walthamstow Leisure Centre',
        alternativeSites: ['clissold-leisure-centre', 'britannia-leisure-centre', 'finsbury-leisure-centre', 'ironmonger-row-baths'],
        transport: {mode: TransportMode.Overground, station: 'St. James Street'},
        coordinates: { lat: 51.5828, lng: -0.0389 }
    },
    "crystal-palace-leisure-centre": {
        provider: 'better',
        displayName: 'Crystal Palace National Sports Centre',
        alternativeSites: ['Balham', 'brixton-recreation-centre', 'canons-leisure-centre', 'Tooting', 'flaxman-sports-centre'],
        transport: {mode: TransportMode.NationalRail, station: 'Crystal Palace'},
        coordinates: { lat: 51.4214, lng: -0.0726 }
    },
    "canons-leisure-centre": {
        provider: 'better',
        displayName: 'Canons Leisure Centre',
        alternativeSites: ['Tooting', 'Balham', 'crystal-palace-leisure-centre', 'Cheam'],
        transport: {mode: TransportMode.NationalRail, station: 'Mitcham Junction'},
        coordinates: { lat: 51.3895, lng: -0.1637 }

    },
    "waterfront-leisure-centre": {
        provider: 'better',
        displayName: 'Waterfront Leisure Centre',
        alternativeSites: ['oasis-sports-centre', 'britannia-leisure-centre', 'finsbury-leisure-centre', 'ironmonger-row-baths'],
        transport: {mode: TransportMode.NationalRail, station: 'Woolwich'},
        coordinates: { lat: 51.4914, lng: 0.0693 }
    },
    "hammersmith-fitness-squash-centre": {
        provider: 'better',
        displayName: 'Hammersmith Fitness and Squash Centre',
        alternativeSites: ['kensington-leisure-centre', 'WestwayPortobello', 'Porchester', 'oasis-sports-centre', 'QueenMother'],
        transport: {mode: TransportMode.Tube, station: 'Hammersmith'},
        coordinates: { lat: 51.4936, lng: -0.2256 }
    },
    "finsbury-leisure-centre": {
        provider: 'better',
        displayName: 'Finsbury Leisure Centre',
        alternativeSites: ['ironmonger-row-baths', 'britannia-leisure-centre', 'oasis-sports-centre', 'clissold-leisure-centre', 'QueenMother'],
        transport: {mode: TransportMode.Tube, station: 'Old Street'},
        coordinates: { lat: 51.5275, lng: -0.1044 }
    },
    "brixton-recreation-centre": {
        provider: 'activeLambeth',
        displayName: 'Brixton Recreation Centre',
        alternativeSites: ['flaxman-sports-centre', 'QueenMother', 'Balham', 'oasis-sports-centre', 'crystal-palace-leisure-centre'],
        transport: {mode: TransportMode.Tube, station: 'Brixton'},
        coordinates: { lat: 51.4627, lng: -0.1146 }
    },
    "flaxman-sports-centre": {
        provider: 'activeLambeth',
        displayName: 'Flaxman Sports Centre',
        alternativeSites: ['brixton-recreation-centre', 'Balham', 'QueenMother', 'oasis-sports-centre', 'crystal-palace-leisure-centre'],
        transport: {mode: TransportMode.NationalRail, station: 'Loughborough Junction'},
        coordinates: { lat: 51.4684, lng: -0.1054 }
    },
    "ironmonger-row-baths": {
        provider: 'better',
        displayName: 'Ironmonger Row Baths',
        alternativeSites: ['finsbury-leisure-centre', 'britannia-leisure-centre', 'oasis-sports-centre', 'clissold-leisure-centre', 'QueenMother'],
        transport:{mode: TransportMode.Tube, station: 'Old Street'},
        coordinates: { lat: 51.5314, lng: -0.0889 }
    },
    "QueenMother": {
        provider: 'everyoneActive',
        displayName: 'Queen Mother Sports Centre',
        alternativeSites: ['oasis-sports-centre', 'brixton-recreation-centre', 'Porchester', 'flaxman-sports-centre', 'hammersmith-fitness-squash-centre'],
        transport: {mode: TransportMode.Tube, station: 'Victoria'},
        coordinates: { lat: 51.4936, lng: -0.1444 }
    },
    "Porchester": {
        provider: 'everyoneActive',
        displayName: 'Porchester Centre',
        alternativeSites: ['WestwayPortobello', 'kensington-leisure-centre', 'hammersmith-fitness-squash-centre', 'oasis-sports-centre', 'QueenMother'],
        transport: {mode: TransportMode.Tube, station: 'Queensway'},
        coordinates: { lat: 51.5139, lng: -0.1918 }

    },
    "WestwayPortobello": {
        provider: 'everyoneActive',
        displayName: 'Westway Portobello Fitness Club',
        alternativeSites: ['Porchester', 'kensington-leisure-centre', 'hammersmith-fitness-squash-centre', 'oasis-sports-centre', 'swiss-cottage-leisure-centre'],
        transport: {mode: TransportMode.Tube, station: 'Ladbroke Grove'},
        coordinates: { lat: 51.5215, lng: -0.2084 }
    },
    "Cheam": {
        provider: 'everyoneActive',
        displayName: 'Cheam Leisure Centre',
        alternativeSites: ['Tolworth', 'canons-leisure-centre', 'Tooting', 'Balham'],
        transport: {mode: TransportMode.NationalRail, station: 'West Sutton'},
        coordinates: { lat: 51.3594, lng: -0.2147 }
    },
    "Harrow": {
        provider: 'everyoneActive',
        displayName: 'Harrow Leisure Centre',
        alternativeSites: ['swiss-cottage-leisure-centre', 'WestwayPortobello', 'kensington-leisure-centre', 'Porchester'],
        transport: {mode: TransportMode.Overground, station: 'Harrow & Wealdstone'},
        coordinates: { lat: 51.5883, lng: -0.3344 }
    }
}

export const getTransportLogoPath = (location: string) => {
    switch (Locations[location].transport.mode) {
        case TransportMode.Tube: 
            return 'LULRoundel.png'
        case TransportMode.Overground: 
            return 'OvergroundRoundel.png'
        case TransportMode.NationalRail: 
            return 'NRlogo.png'
    }
}

export const getTransportLogoAltText = (location: string) => {
    switch (Locations[location].transport.mode) {
        case TransportMode.Tube: 
            return 'London Underground Roundel'
        case TransportMode.Overground: 
            return 'Overground Roundel'
        case TransportMode.NationalRail: 
            return 'National Rail logo'
    }
}

export const getMapsLink = (location: string) => {
    let name = Locations[location].displayName
    let nameReplaced = name.replace(/ /g, "+");
    var isAndroid = /Android/i.test(navigator.userAgent);
    if (isAndroid) {
        return `intent://maps.google.com/maps/search/?api=1&query=${nameReplaced},+London#Intent;scheme=https;package=com.google.android.apps.maps;end`
    } else {
        return `https://www.google.com/maps/search/?api=1&query=${nameReplaced},London`
    }
    
}

interface ProviderDisplayNames {
    better: string;
    places: string;
    activeLambeth: string;
    [key: string]: string;
}

export const providerDisplayNames: ProviderDisplayNames = {
    better: 'Better',
    places: 'Places Leisure',
    activeLambeth: 'Active Lambeth',
    everyoneActive: 'Everyone Active'
}

const placesUrl = (locationString: string, activity: string) => `https://www.placesleisure.org/centres/${locationString}/centre-activities/${activity}/#timetable`;
const betterUrl = (locationString: string, date: string) => `https://bookings.better.org.uk/location/${locationString}/squash-court-40min/${date}/by-time`;
const activeLambethUrl = (locationString: string, date: string) => `https://lambethcouncil.bookings.flow.onl/location/${locationString}/squash-court-40min/${date}/by-time`;
const everyoneActiveUrl = (locationCode: string) => `https://profile.everyoneactive.com/booking?Site=0${locationCode}&ActivityGroups=${locationCode}SQUASH&Culture=en-GB`

export const bookingLink = (location: string, date: string) => {
    switch(Locations[location].provider) {
        case 'places':
            switch(location) {
                case 'Balham':
                    return placesUrl('balham-leisure-centre', 'squash');
                case 'Tooting':
                    return placesUrl('tooting-leisure-centre', 'sports');
                case 'Tolworth':
                    return placesUrl('tolworth-recreation-centre', 'sports');
                case 'Epping':
                    return placesUrl('epping-sports-centre', 'sports');
                }
            break;
        case 'better':
            return betterUrl(location, date);
        case 'activeLambeth':
            return activeLambethUrl(location, date);
        case 'everyoneActive':
            switch(location) {
                case 'QueenMother':
                    return everyoneActiveUrl('155');
                case 'Porchester':
                    return everyoneActiveUrl('153');
                case 'WestwayPortobello':
                    return everyoneActiveUrl('163');
                case 'Cheam':
                    return everyoneActiveUrl('073');
                case 'Harrow':
                    return everyoneActiveUrl('097');
            }
    }
} 

export const locationsByProvider: Record<string, [string, CourtLocation][]> = Object.entries(Locations).reduce((acc: Record<string, [string, CourtLocation][]>, [locationName, location]: [string, CourtLocation]) => {
    if (!acc[location.provider]) {
        acc[location.provider] = [];
    }
    acc[location.provider].push([locationName, location]);
    acc[location.provider].sort((a, b) => a[0].localeCompare(b[0]));
    return acc;
}, {});

// The number of days in the future that can be searched
export const providerSearchHorizon: Record<string, number> = {
    "activeLambeth": 6,
    "better": 5,
    "places": 8,
    "everyoneActive": 7
};
