export type Coords = { lat: number, lng: number };

export const haversineDistance = (coords1: Coords, coords2: Coords) => {
    const toRad = (angle: number) => (Math.PI / 180) * angle;
  
    const lat1 = coords1.lat;
    const lng1 = coords1.lng;
    const lat2 = coords2.lat;
    const lng2 = coords2.lng;
  
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = toRad(lat2 - lat1);
    const dLng = toRad(lng2 - lng1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in kilometers
  };