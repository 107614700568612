import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { formatPrice, formatTime, slotDuration, formatApiError } from "../utils/Formatting";
import { ApiReponseError, CourtSlot } from "../utils/GetAvailabilities";

type ResultCardProps = {
    slot: CourtSlot | null,
    link: string,
    textIfEmpty: string
}

function ResultCard({slot, link, textIfEmpty}: ResultCardProps) {
    return (
        <Col xs={4} style={{padding: 2}}>
            <Card border="primary">
                {slot ? (
                    <Card.Body>
                        <Card.Title style={{whiteSpace: 'nowrap'}}>
                            {formatTime(slot.startTime)}
                        </Card.Title>
                        <Card.Text style={{whiteSpace: 'nowrap'}}>
                            {slotDuration(slot)} mins
                        </Card.Text>
                        <Card.Text>
                            {formatPrice(slot.price)}
                        </Card.Text>
                        {slot.availability !== null ? (
                            <Card.Text style={{fontSize: '0.9rem', whiteSpace: 'nowrap'}}>
                                {slot.availability} court{slot.availability > 1 && 's'}
                            </Card.Text>
                        ) : (
                            <Card.Text style={{fontSize: '0.9rem', whiteSpace: 'nowrap'}}>
                                1+ courts
                            </Card.Text>
                        )}
                        <Card.Link href={link} target="_blank">
                            Book
                        </Card.Link>
                    </Card.Body>
                ) : (
                    <Card.Body>
                        <Card.Text>
                            {textIfEmpty}
                        </Card.Text>
                    </Card.Body>
                )}
            </Card>
        </Col>
        
    )
}

type FinderResultsViewProps = {
    closest: CourtSlot | null,
    before: CourtSlot | null,
    after: CourtSlot | null,
    errors: ApiReponseError[],
    link: string,
    isAlternative: boolean
}

function FinderResultsView({closest, before, after, errors, link, isAlternative}: FinderResultsViewProps) {
    const showBefore = before || !isAlternative
    const showAfter = after || !isAlternative
    return (
        <>
            {closest != null && <Row className="no-gutters">
                {showBefore && <ResultCard slot={before} link={link} textIfEmpty="No earlier courts"/>}
                <ResultCard slot={closest} link={link} textIfEmpty="No courts available"/>
                {showAfter && <ResultCard slot={after} link={link} textIfEmpty="No later courts"/>}
            </Row>}
            {closest == null && <>
                    {errors.map(e => <div className="p-2" key={`${e.date}_error`}>{formatApiError(e)}</div>)}
                </> }   
        </>
    )
}

export default FinderResultsView;